<template>
  <div class="billing-address">

  

  <!-- <vs-input class="inputx simple-input mb-2" :label-placeholder="$t('language')" 
        v-model="customer.customer_params" /> -->

    
    <vs-row class="w-100">
      <vs-col vs-w="4">
        <vs-input type="number" min="0" step="1" class="inputx spec-input simple-input mb-2" :label-placeholder="$t('Ablauffrist')" 
            v-model="customerConfiguration.expiry_warning_days"/>
      </vs-col>
      <vs-col vs-w="1"></vs-col>
      <vs-col vs-w="7">
        <div  class="mt-4">
          <small>Tage(e) bis "Gültig bis"-Datum</small>
        </div>
      </vs-col>
    </vs-row>

    <vs-row class="w-100">
      <vs-col vs-w="4">
        <vs-input type="number" min="0" step="1" class="inputx spec-input  simple-input mb-2" :label-placeholder="$t('Gültigkeitsdauer')" 
            v-model="customerConfiguration.id_card_validity"/>
      </vs-col>
      <vs-col vs-w="1"></vs-col>
      <vs-col vs-w="7">
        <div  class="mt-4">
          <small>Jahr(e) ab "Erstellt am"-Datum</small>
        </div>
      </vs-col>
    </vs-row>



    <vs-row class="w-100">
        <vs-col vs-type="flex" vs-justify="right" vs-align="right" vs-w="12">
          <vs-button class="pop-btn organ-btn" color="primary" type="filled" icon="done"
              @click="editCustomerConfiguration()">
              {{$t('Speichern')}}</vs-button>
        </vs-col>
    </vs-row>


    <!-- DEBUG MODE -->
    <div v-if="isDebugMode">
      <h1>Customer Configuration:</h1>
        {{customerConfiguration}}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import _ from 'lodash'

export default Vue.extend({
  name: 'Miscellaneous',
  
  async mounted() { 
    // await this.$store.dispatch('customer/loadCustomer', this.user.customer_id)
    await this.$store.dispatch('customer/loadCustomerConfiguration', this.user.customer_id)
    this.customerConfiguration = _.cloneDeep(this.$store.getters['customer/getCustomerConfiguration'])
  },  


  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
    customer() {
      return this.$store.getters['customer/getCustomer']
    },
  },

  data() {
    return {
      customerConfiguration: {}
    }
  },

  watch: {
    customerConfiguration: {
      deep: true,
      handler() {
        if (Math.round(Number(this.customerConfiguration.id_card_validity)) < 1) {
          this.customerConfiguration.id_card_validity = 1
        } else {
          this.customerConfiguration.id_card_validity = Math.round(Number(this.customerConfiguration.id_card_validity))
        }
        if (Math.round(Number(this.customerConfiguration.expiry_warning_days)) < 1) {
          this.customerConfiguration.expiry_warning_days = 1
        } else {
          this.customerConfiguration.expiry_warning_days = Math.round(Number(this.customerConfiguration.expiry_warning_days))
        }
      }
    }
  },

  methods: {
    async editCustomerConfiguration() {
      let status = await this.$store.dispatch('customer/updateCustomerConfiguration', this.customerConfiguration)
      if (status === 200) {
        this.$vs.notify({
          color: "success",
          position: "bottom-right",
          title: "Daten wurden gespeichert",
        });
      } else {
        errorNotifier(status)
      }
    }
  }
});
</script>
